html,
body {
 width: 100%;
 height: auto;
}

body {
 
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  background: linear-gradient(to right, #000000, #0b0b0c,rgb(201, 15, 71));
  background-size: 200% 200%;
  background-repeat: no-repeat;
}


/* body::before { */
  /* content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;  
  background: radial-gradient(circle, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.3) 80%);
  z-index: -1;
  pointer-events: none; */
  /* animation: twinkling 2s infinite; */
/* } */

@keyframes twinkling {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0.2;
   
  }
  20% {
    opacity: 0.2;
    color:rgb(40, 42, 46);
  }
  40% {
    opacity: 0.2;
    color:fuchsia;
   
  }
  60% {
    opacity: 0.2;
    color:deeppink;
   
  }
  80% {
    opacity: 0.2;
color:orange;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.1;
  }
}
